import { combineReducers } from 'redux'
import userReducer from './user'
import valuationReducer from './valuation'
import vehiclesReducer from './vehicles'
import referralReducer from './referral'
import buyDetailReducer from './buyDetail'

export const makeRootReducer = (asyncReducers) => {
  return combineReducers({
    user: userReducer,
    valuation: valuationReducer,
    vehicles: vehiclesReducer,
    referral: referralReducer,
    buyDetail: buyDetailReducer,
    ...asyncReducers
  })
}

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
