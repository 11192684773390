import * as envDev from 'env.development'
import * as envDeployed from 'env.deployed'
import * as envTesting from 'env.testing'
let env = {}
if (__DEV__ || __TEST__) {
  env = envDev
} else if (__TESTING__) {
  env = envTesting
} else {
  env = envDeployed
}
export default env
