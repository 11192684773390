import { createBrowserHistory, createMemoryHistory } from 'history'
// import { parse, stringify } from 'qs'
// import qhistory from 'qhistory'

let history
if (typeof document === 'undefined') {
  history = createMemoryHistory()
} else {
  history = createBrowserHistory()
}

// const history = qhistory(
//   browserHistory,
//   parse,
//   stringify
// )

export default history
