import React from 'react'
import PropTypes from 'prop-types'
// import { Link } from 'react-router-dom'
import Wrapper from 'components/Wrapper'
import Grid from 'components/Grid'
import GridItem from 'components/GridItem'
import BackgroundImage from './img/herobg.jpg'
// import VehicleSearch from 'components/Header/VehicleSearch'
// import PhoneNumber from 'components/PhoneNumber'
import { getRandomInt } from 'services/helpers'
import './HeroSearch.scss'

export default class HeroSearch extends React.Component {
  static defaultProps = {
    size: 'large'
  }

  static propTypes = {
    size: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      vehicleName: ''
    }
  }

  componentDidMount() {
    const vehicleName = this.getRandomVehicleName()
    this.setState({ vehicleName })
  }

  getRandomVehicleName() {
    const vehicles = [
      "Mercedes-Benz",
      "BMW",
      "Chevrolet",
      "Honda",
      "Toyota",
      "Ford",
      "Ford Mustang",
      "Jeep",
      "Ford F150",
      "Chevrolet Camaro"
    ]
    return vehicles[getRandomInt(10)]
  }

  render() {
    const { size } = this.props
    const { loaded } = this.state
    // const placeholder = `Try '${vehicleName}'`

    return (
      <div className="HeroSearch" style={{ 'backgroundImage' : 'url(' + BackgroundImage + ')' }} data-size={size}>
        <div className="BGOverlay" />
        <Wrapper v={2}>
          <Grid v={2}>
            <GridItem v={2} size={12}>
              <div className="HeroText" data-loaded={loaded} ref={ref => { this.heroText = ref }}>
                <h2>A person-to-person marketplace for buying and selling cars.</h2>
                <h4>Due to the impact of COVID-19 and in accordance with government health regulations, Carable will be temporarily closed. We at Carable greatly value the health of our customers and we look forward to being able to serve you in the near future.</h4>
                {/* <VehicleSearch placeholder={placeholder} inputId="hero-input" /> */}
                {/* <div className="BottomLinks">
                  <Link to="/how-it-works">
                    <p><strong>How does Carable work?</strong></p>
                  </Link>
                  <Link to="/sell">
                    <p><strong>Sell my car</strong></p>
                  </Link>
                  <div className="HiddenMobile">
                    <p><strong><PhoneNumber label="Speak to someone: %n" /></strong></p>
                  </div>
                </div> */}
              </div>
            </GridItem>
          </Grid>
        </Wrapper>
      </div>
    )
  }
}
