import env from 'env'
import authReq from 'services/authReq'

export function postSbaTrackingId(trackingId) {
  const url = `${env.payAPI}/api/v1/financing/sb-tempfinancing?SubId=${trackingId}`
  return authReq()
    .post(url)
    .catch(err => {
      console.log(err.message)
    })
}

export function setPayoutAccount(id) {
  const url = `${env.payAPI}/api/v1/users/bankaccounts/${id}/payoutaccount`
  return authReq()
    .put(url)
}

export function getBankToken() {
  const url = `${env.payAPI}/api/v1/Users/tokens/bank`
  return authReq()
    .get(url)
    .then(resp => resp.data)
}

export function getBankAccounts() {
  const url = `${env.payAPI}/api/v1/Users/bankaccounts`
  return authReq()
    .get(url)
    .then(resp => resp.data)
}

export function sellerInformationRequired() {
  const url = `${env.payAPI}/api/v1/Users/seller-information-required`
  return authReq().get(url).then(resp => resp.data)
}

export function postSellerInformation(data) {
  const url = `${env.payAPI}/api/v1/Users/seller-information`
  return authReq().post(url, data).then(resp => resp.data)
}

export default {
  getBankAccounts,
  getBankToken,
  postSbaTrackingId,
  setPayoutAccount,
  sellerInformationRequired,
  postSellerInformation
}
