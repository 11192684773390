import vehicleInfoService from 'services/vehicleInfoService'

export const VALUATION_REQUEST = 'VALUATION_REQUEST'
export const VALUATION_SUCCESS = 'VALUATION_SUCCESS'
export const VALUATION_FAILURE = 'VALUATION_FAILURE'
export const VALUATION_RESET = 'VALUATION_RESET'

function requestValuation() {
  return {
    type: VALUATION_REQUEST,
    isFetching: true
  }
}

function receiveValuation(vehicleInfoId, valuation) {
  return {
    type: VALUATION_SUCCESS,
    isFetching: false,
    data: {
      vehicleInfoId,
      valuation
    }
  }
}

function valuationError(err) {
  return {
    type: VALUATION_FAILURE,
    isFetching: false,
    error: err
  }
}

export function getValuation(query) {
  return async dispatch => {
    dispatch(requestValuation())

    try {
      const resp = await vehicleInfoService.createQuickValuation(query)
      const valuation = {
        retail: resp.quickValuationData.retail,
        tradeIn: resp.quickValuationData.tradeIn,
        options: resp.quickValuationData.pricingAdjustments,
      }

      return dispatch(receiveValuation(resp.vehicleInfoId, valuation))
    } catch (err) {
      return dispatch(valuationError(err.message))
    }
  }
}

export function resetValuation() {
  return {
    type: VALUATION_RESET
  }
}

const initialState = {
  isFetching: false,
  error: '',
  hasValuation: false,
  vehicleInfoId: null,
  valuation: {
    max: 0,
    min: 0,
    saving: 0
  }
}

export default function valuation(state = initialState, action) {
  switch (action.type) {
    case VALUATION_REQUEST:
      return {
        ...state,
        isFetching: true,
        hasValuation: false,
        vehicleInfoId: null,
        valuation: {
          ...initialState.valuation
        }
      }
    case VALUATION_SUCCESS:
      const { vehicleInfoId, valuation } = action.data
      return {
        ...state,
        isFetching: false,
        hasValuation: true,
        vehicleInfoId: vehicleInfoId,
        valuation: {
          ...valuation
        }
      }
    case VALUATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    case VALUATION_RESET:
      return initialState
    default:
      return state
  }
}
