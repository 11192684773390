import moment from 'moment'
import algoliasearch from 'algoliasearch'
import { OFFER_STATUS_PLACED } from 'services/saleService'
import env from 'env'

export function setTotalVehicles() {
  const searchClient = algoliasearch(env.searchAppId, env.searchApiKey)
  const index = searchClient.initIndex(env.searchIndex)
  index.search({ query: '' }).then(res => {
    sessionStorage.setItem('totalHits', res.nbHits)
  })
}

export async function getTotalVehicles() {
  const searchClient = algoliasearch(env.searchAppId, env.searchApiKey)
  const index = searchClient.initIndex(env.searchIndex)
  return index.search({ query: '' }).length
}

export function getVehiclesOnLocation(lat, lng, radius) {
  const searchClient = algoliasearch(env.searchAppId, env.searchApiKey)
  const index = searchClient.initIndex(env.searchIndex)
  const params = {
    aroundLatLng: `${lat}, ${lng}`,
    aroundRadius: radius,
    hitsPerPage: 40
  }
  return index.search(params).then(res => res.hits)
}

export function getVehiclesByMake(make) {
  const searchClient = algoliasearch(env.searchAppId, env.searchApiKey)
  const index = searchClient.initIndex(env.searchIndex)
  return index.search(make, { hitsPerPage: 40 }).then(res => res.hits)
}

export function titleCase(str) {
  if (!str || !str.length) return ''

  str = str.toLowerCase().split(' ')

  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].split('')
    str[i][0] = str[i][0].toUpperCase()
    str[i] = str[i].join('')
  }
  return str.join(' ')
}

// Borrowed from Lodash
export function isObject(value) {
  var type = typeof value
  return !!value && (type === 'object' || type === 'function')
}

export function auctionSorter(a, b) {
  const d1 = moment(a.startDate)
  const d2 = moment(b.startDate)

  if (d1.isBefore(d2)) {
    return -1
  }

  if (d1.isAfter(d2)) {
    return 1
  }

  if (d1.isSame(d2)) {
    return 0
  }
}

export function offerSorter(a, b) {
  if (a.amount > b.amount) {
    return -1
  }

  if (a.amount < b.amount) {
    return 1
  }

  if (a.amount === b.amount) {
    return 0
  }
}

export function sortOffersByDate(a, b) {
  const aPlaced = moment(a.offerPlaced)
  const bPlaced = moment(b.offerPlaced)

  if (aPlaced > bPlaced) {
    return -1
  }

  if (aPlaced < bPlaced) {
    return 1
  }

  if (aPlaced === bPlaced) {
    return 0
  }
}

export function getMostRecentAuction(saleContract) {
  const sc = [...saleContract.auctions]
  return sc.find((a) => (a.isOngoing)) || sc.sort(auctionSorter).pop()
}

export function getMostRecentFixedPrice(saleContract) {
  const sc = [...saleContract.fixedPrices]
  return sc.find((a) => (a.isOngoing)) || sc.sort(auctionSorter).pop()
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max))
}

export function vehicleUri(make, model, year, scId) {
  return `${make}-${model}-${year}`.replace(' ', '-').toLowerCase() + `-${scId}`
}

export function kFormatter(num, symbol, fixed) {
  const prefixSymbol = symbol || ''
  const toFixed = Number.isInteger(fixed) ? fixed : 1
  return num > 999 ? prefixSymbol + (num / 1000).toFixed(toFixed) + 'k' : prefixSymbol + num
}

export function countPlacedOffers(offers) {
  let placed = 0

  offers.forEach((offer) => {
    if (offer.status === OFFER_STATUS_PLACED) {
      placed += 1
    }
  })

  return placed
}
