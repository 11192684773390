import axios from 'axios'
import Cookies from 'js-cookie'
import Tokens from './tokens.js'
import Accounts from './accounts.js'

function mapToUserAddress(a) {
  return {
    addressline : a.addressline || '',
    country: a.country || '',
    city: a.city || '',
    zipcode: a.zipcode || '',
    state: a.state || ''
  }
}

function mapToFullUser(pu) {
  let data = {
    id: pu.id || '',
    address: {},
    email: pu.email || '',
    name: pu.name,
    phone: pu.phoneNumber ? pu.phoneNumber : ''
  }
  data.address = mapToUserAddress(pu.addressDetails)
  return data
}

function url(path) {
  const authority = window.__AUTHORITY__
  return [authority, path.replace(/^\//g, '')].join('/')
}

class AuthService {
  constructor() {
    this._accounts = new Accounts()
    this._tokens = new Tokens(this._accounts)
  }

  getUser() {
    return this._tokens.get().then(token => {
      return { id_token: token, access_token: token }
    })
  }

  getToken() {
    return this._tokens.get()
  }

  loggedIn() {
    return this._tokens.active()
  }

  login() {
    window.location.href = '/login'
  }

  setTokenIfSignedIn() {
    return this._tokens.active() // side effect of token get is to set the token
  }

  checkIfEmailExist(email) {
    return this._accounts.canHasEmail(email)
  }

  signIn(username, password, rememberMe) {
    return this._accounts.get().then(() => {
      return this._accounts.signin({
        username: username,
        password: password,
        rememberMe: rememberMe
      })
    })
  }

  impersonateUserWithId(id) {
    return this._accounts.get().then(() => {
      return this._accounts.impersonate(id).then(response => {
        this._tokens.use(response.data)
      })
    })
  }

  signInWithToken(email, token) {
    return this._accounts.get().then(resp => {
      return this._accounts.signInWithToken({
        email,
        token
      })
    })
  }

  getCookie(name) {
    var value = '; ' + document.cookie
    var parts = value.split('; ' + name + '=')
    if (parts.length === 2) return parts.pop().split(';').shift()
  }

  getGoogleClickId() {
    var googleClickId = this.getCookie('_ga')
    if (googleClickId === undefined) {
      googleClickId = ''
    }

    return googleClickId
  }

  signUp(registerInfo) {
    let registerModel = {
      googleClickId: this.getGoogleClickId(),
      facebookAdId: '',
      password: registerInfo.password,
      rememberMe: true,
      addressDetails: {
        addressline: registerInfo.address,
        city: registerInfo.city,
        zipcode: registerInfo.zipcode,
        country: 'USA',
        state: registerInfo.state
      },
      email: registerInfo.email,
      name: {
        first: registerInfo.name.first,
        last: registerInfo.name.last
      },
      phoneNumber: registerInfo.phonenumber
    }
    return this._accounts.signUp(registerModel)
  }

  logout() {
    return this._accounts.get().then((loggedOn) => {
      sessionStorage.clear()
      localStorage.clear()
      if (!loggedOn) {
        return Promise.resolve(true)
      }

      return this._accounts.signout()
    })
  }

  emailExists(email) {
    return this._accounts.get().then(isLoggedOn => {
      if (isLoggedOn) {
        Promise.reject(new Error('AlreadyLoggedOn')) // I.e. we do not need to do the extra request
      }
      return this._accounts.emailExists(email)
    })
  }

  // User can't log in and clicks forgot password
  resetPassword(email) {
    return this._accounts.get().then(() => {
      const request = axios.create({
        timeout: 10000,
        withCredentials: true,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN')
        }
      })

      return request.post(url('/api/v2/accounts/forgot-password'), { 'email': email })
    })
  }

  // User is changing password after email link redirect
  changePassword(changePasswordInfo) {
    return this._accounts.get().then(() => {
      const request = axios.create({
        timeout: 10000,
        withCredentials: true,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN')
        }
      })
      const data = {
        'userId': changePasswordInfo.userId,
        'code': changePasswordInfo.code,
        'password': changePasswordInfo.password
      }

      return request.post(url('/api/v2/accounts/reset-password'), data)
    })
  }

  // User is changing password through my-pages
  updatePassword(pass) {
    return this._accounts.get().then(() => {
      const request = axios.create({
        timeout: 10000,
        withCredentials: true,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN')
        }
      })
      const data = {
        'currentPassword': pass.old.value,
        'password': pass.new.value
      }
      return request.post(url('/api/v2/accounts/change-password'), data).then((response) => {
      }).catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          console.error('Received a 401')
          return Promise.reject(new Error('not_logged_in'))
        }
        return Promise.reject(new Error(error))
      })
    })
  }

  getUserInfo() {
    return this.getUser()
      .then(function(u) {
        const request = axios.create({
          timeout: 10000,
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + u.access_token
          }
        })
        return request.get(url('/api/v2/user'))
          .then(response => { return { u:u, info: response.data } })
          .catch(function(error) {
            if (error && error.response && error.response.status === 401) {
              return Promise.reject(new Error('not_logged_in'))
            }
            return Promise.reject(new Error(error))
          })
      })
      .then((uAndPu) => {
        let u = uAndPu.u
        let pu = uAndPu.info
        let user = mapToFullUser(pu)
        return { info:user, user:u }
      })
  }

  updateUserBasicInformation(userInfo) {
    return this.getUser().then((u) => {
      const request = axios.create({
        timeout: 10000,
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + u.access_token,
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN')
        }
      })
      return request.put(url('/api/v2/User/profile'), JSON.stringify({
        name: userInfo.name,
        email: userInfo.email,
        phoneNumber: userInfo.phone
      })).then((response) => {
        let pu = response.data
        let user = mapToFullUser(pu)
        return { info:user, user:u }
      }).catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          return Promise.reject(new Error('not_logged_in'))
        }
        return Promise.reject(new Error(error))
      })
    })
  }

  updateUserAddress(userAddress) {
    return this.getUser().then((u) => {
      const request = axios.create({
        timeout: 10000,
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + u.access_token,
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN')
        }
      })
      return request.put(url('/api/v2/User/address'), JSON.stringify({
        addressline: userAddress.addressline,
        city: userAddress.city,
        country: 'USA',
        state: userAddress.state,
        zipcode: userAddress.zipcode
      })).then((response) => {
        let pu = response.data
        let user = mapToFullUser(pu)
        return { info:user, user:u }
      }).catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          console.error('Received a 401')
          return Promise.reject(new Error('not_logged_in'))
        }
        return Promise.reject(new Error(error))
      })
    })
  }
}

export default new AuthService()
