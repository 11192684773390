import React from 'react'
import { Link } from 'react-router-dom'

export default class Navigation extends React.Component {
  render() {
    return (
      <div className="Navigation">
        <nav>
          <ul>
            {/* <li><Link to="/for-sale">Buy a Car</Link></li>
            <li><Link to="/sell">Sell a Car</Link></li> */}
            <li><Link to="/how-it-works">How it Works</Link></li>
            <li><Link to="/about">About</Link></li>
          </ul>
        </nav>
      </div>
    )
  }
}
