export const vehicleInfoAPI = 'https://vehicleinfo-test.carable.com'
export const bookingAPI = 'https://booking-test.carable.com'
export const saleAPI = 'https://sale-test.carable.com'
export const asmPayLib = 'https://js.prelive.promisepay.com/PromisePay.js'
export const asmPayEnv = 'prelive'
export const payAPI = 'https://payment-test.carable.com'
export const geoAPI = 'https://geo-test.carable.com'
export const mailAPI = 'https://mail-test.carable.com'
export const searchIndexRecentlyAdded = 'testing_VEHICLESEARCH_recently_added'
export const searchIndexMileage = 'testing_VEHICLESEARCH_mileage'
export const searchIndexCurrentPriceAsc = 'testing_VEHICLESEARCH_current_price_asc'
export const searchIndexCurrentPriceDesc = 'testing_VEHICLESEARCH_current_price_desc'
export const searchIndexSold = 'testing_VEHICLESEARCH_sold'
export const searchIndexLocations = 'testing_BOOK_LOCATIONSEARCH'
export const searchIndex = 'testing_VEHICLESEARCH'
export const searchAppId = 'APUFCNKUNJ'
export const searchApiKey = 'fbf57bdb1bb08f4ba3efe849120d0a28'
export const MapboxAccessToken = 'pk.eyJ1IjoiY2FyYWJsZSIsImEiOiJjajcxeGY5a2kwNG04MnFvN3B3OHZieW81In0.MUjDxO-M8Eki9dFu6rnLMw'
export const buyerFAQIndex = 'buyer_FAQ'
export const sellerFAQIndex = 'seller_FAQ'
export const mixpanelToken = '911b54bf65ca2dd5a98b33ef54916b3f'
export const firebaseApiKey = 'AIzaSyAn7GYWImsG6hcVnbpNmZx521lIKmUedoU'
export const firebaseAuthDomain = 'carable-staging.firebaseapp.com'
export const firebaseDBUrl = 'https://carable-staging.firebaseio.com'
export const webRootUrl = 'https://carable.club'
export const financeUrl = 'https://www.myautoloan.com/partner/carable.html?utm_distil=carable&source=carable&site=MAL&type=web&term=<SUB_ID>'
export const firebaseIPKey = 'AIzaSyAtHUXfmqYlkMTJz1s7plJnquE6C12JS6U'
export const firebaseIPAuthDomain = 'carable-ip-staging.firebaseapp.com'
export const firebaseIPDBUrl = 'https://carable-ip-staging.firebaseio.com'
export const stripePublicKey = 'pk_test_PT4wk6V08uZNI0DAHOLHrJiB'
export const formspreeId = 'xaolkkax'
export const formspreeTestDriveId = 'mnqqgdgl'
