import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './MobileMenu.scss'

import { Link } from 'react-router-dom'
import Button from 'components/Button'

const MobileMenu = ({ isAuthenticated, navigationIsOpen }) => {
  const signInOrSignOut = () => {
    if (isAuthenticated) {
      return (<Button link="/logout" label="Sign out" type="primary" size="small" />)
    } else {
      return (<Button link="/login" label="Sign in" type="primary" size="small" />)
    }
  }

  return (
    <div className="MobileMenu" data-active={navigationIsOpen}>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about">About</Link></li>
        {/* <li><Link to="/for-sale">Buy a Car</Link></li>
        <li><Link to="/sell">Sell a Car</Link></li> */}
        <li><Link to="/buyer-faq">Buyer FAQ</Link></li>
        <li><Link to="/seller-faq">Seller FAQ</Link></li>
        <li><Link to="/how-it-works">How it works</Link></li>
        {/* <li><Link to="/account">My account</Link></li> */}
        {/* <li><a href="tel:+14697304455">Call us</a></li> */}
      </ul>
      <div className="ButtonWrapper">
        {signInOrSignOut()}
      </div>
    </div>
  )
}

MobileMenu.propTypes = {
  isAuthenticated: PropTypes.bool,
  navigationIsOpen: PropTypes.bool
}

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    isAuthenticated: state.user.isAuthenticated
  }
}

export default connect(mapStateToProps)(MobileMenu)
