import React from 'react'
import PropTypes from 'prop-types'
import './Grid.scss'

export const Grid = ({ gutter, padded, type, children, v }) => {
  return (
    <div
      className="Grid"
      data-padded={padded}
      data-type={type}
      data-v={v}
      data-gutter={gutter}
    >
      {children}
    </div>
  )
}

export default Grid

Grid.defaultProps = {
  type: 'fixed',
  padded: false
}

Grid.propTypes = {
  type: PropTypes.string,
  padded: PropTypes.bool,
  children: PropTypes.node,
  v: PropTypes.number,
  gutter: PropTypes.number
}
