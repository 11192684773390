import React from 'react'
import './HomeView.scss'
import PageLayout from 'layouts/PageLayout'
import history from 'utils/history'
import { Helmet } from 'react-helmet'
import HeroSearch from 'components/HeroSearch'
// import HowItWorks from 'components/HowItWorks123'
// import PartnerPanel from 'components/PartnerPanel'
// import Testimonials from 'components/Testimonials'
// import VehicleCollectionsOverview from 'components/VehicleCollectionsOverview'
import CarableValueProposition from 'components/CarableValueProposition'
// import Wrapper from 'components/Wrapper'
// import Grid from 'components/Grid'
// import GridItem from 'components/GridItem'
// import Button from 'components/Button'
import { organization } from 'utils/structuredData'

class HomeView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showingHowItWorks: 'buy'
    }

    this.handleSectionChange = this.handleSectionChange.bind(this)
  }

  handleSectionChange(e) {
    e.preventDefault()
    this.setState({ showingHowItWorks: e.target.value })
  }

  handleValuationSubmit() {
    history.push('/sell')
  }

  render() {
    return (
      <div className="HomeScrollWrapper" >
        <PageLayout>
          <div className="Homeview Homeview--Default">
            <Helmet>
              <script type="application/ld+json">
                {JSON.stringify(organization())}
              </script>
            </Helmet>
            <HeroSearch ref={ref => { this.hero = ref }} />
            <div className="HomeViewSellCTA">
              {/* <Link to="/sell" id="SellHeroButton">
                Selling a car? Click here for a free valuation
              </Link> */}
            </div>
            <CarableValueProposition />
            {/* <div className="HomeviewCollections">
              <Wrapper v={2}>
                <Grid v={2}>
                  <GridItem v={2} size={12}>
                    <VehicleCollectionsOverview showOnly={['recentlyAdded']} />
                  </GridItem>
                </Grid>
              </Wrapper>
              <div className="Buttons">
                <Button type="primary" size="large" label="View all cars" link="/for-sale" />
              </div>
            </div>
            <HowItWorks />
            <PartnerPanel />
            <Testimonials /> */}
          </div>
        </PageLayout>
      </div>
    )
  }
}

export default HomeView
