import Accounts from './accounts.js'

export function parsePayload(jwt) {
  function parseJwt(token) {
    let base64Url = token.split('.')[1]
    let base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  }

  function fromUnixDate(num) {
    let d = new Date(0)
    d.setUTCSeconds(num)
    return d
  }

  if (!jwt) return null
  let token = parseJwt(jwt)
  return {
    exp: fromUnixDate(token.exp),
    auth_time: fromUnixDate(token.auth_time),
    iat: fromUnixDate(token.iat),
    nbf: fromUnixDate(token.nbf),
    idp: token.idp,
    iss: token.iss,
    name: token.name,
    sub: token.sub,
    impi: token.impi
  }
}

class Tokens {
  constructor(accounts) {
    if (accounts === undefined) {
      accounts = new Accounts()
    }
    this._accounts = accounts
    this.get = () => {
      var idToken = () => sessionStorage.getItem('id_token')
      if (!this.check()) {
        // if we are doing a request, we should hang on to the promise for about a sec or two
        return this.reload().then(idToken)
      } else {
        return Promise.resolve(idToken()) // we don't need to hang on to it for long
      }
    }
  }
  check () {
    var jwt = parsePayload(sessionStorage.getItem('id_token'))
    return !!(jwt && (jwt.exp > (new Date())))
  }
  reload() {
    return this._accounts.get().then((loggedOn) => { // will also reload the XSRF token
      if (loggedOn) {
        return ''
      } else {
        return Promise.reject(new Error('not_logged_in'))
      }
    }).then(() => {
      return this._accounts.token().then((r) => { // so now we can request the token
        sessionStorage.setItem('id_token', r.data)
        return 'TokenSet'
      })
    })
  }
  name() {
    return this.get().then(t => parsePayload(t).name)
  }
  use(token) {
    sessionStorage.setItem('id_token', token)
    return Promise.resolve(true)
  }
  active () {
    var returnsTrue = () => true
    if (!this.check()) {
      return this.get().then(returnsTrue).catch(error => {
        if (error.message !== 'not_logged_in') {
          return Promise.reject(error)
        } else {
          return false
        }
      })
    } else {
      return Promise.resolve(returnsTrue())
    }
  }
}

export default Tokens
