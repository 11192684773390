import React from 'react'
import Wrapper from 'components/Wrapper'
import Grid from 'components/Grid'
import GridItem from 'components/GridItem'
import { Link } from 'react-router-dom'
import Logo from 'components/Logo'
import './Footer.scss'

export const Footer = () => (
  <div className="Footer">
    <div className="FooterNav">
      <Wrapper v={2}>
        <div className="FooterNavGroups">
          <div className="FooterNavGroup">
            <strong>Carable</strong>
            {/* <Link to="/for-sale">Buy a car</Link>
            <Link to="/sell">Sell a car</Link> */}
            <Link to="/about">About</Link>
            <Link to="/how-it-works">How it works</Link>
            <Link to="/refer">Referral program</Link>
            <Link to="/privacy">Privacy</Link>
            <Link to="/terms">Terms</Link>
            <Link to="/investors">Investors</Link>
          </div>
          {/* <div className="FooterNavGroup" data-size="large">
            <strong><Link to="/for-sale">Used cars by location</Link></strong>
            <Link to="/for-sale/location/dallas">Used cars in Dallas</Link>
            <Link to="/for-sale/location/fort-worth">Used cars in Fort Worth</Link>
            <Link to="/for-sale/location/houston">Used cars in Houston</Link>
            <Link to="/for-sale/location/plano">Used cars in Plano</Link>
            <Link to="/for-sale/location/irving">Used cars in Irving</Link>
            <Link to="/for-sale/location/frisco">Used cars in Frisco</Link>
            <Link to="/for-sale/location/spring">Used cars in Spring</Link>
          </div>
          <div className="FooterNavGroup" data-size="large">
            <strong><Link to="/for-sale">Used cars by brand</Link></strong>
            <Link to="/for-sale/brand/ford">Used Ford for sale</Link>
            <Link to="/for-sale/brand/jeep">Used Jeep for sale</Link>
            <Link to="/for-sale/brand/toyota">Used Toyota for sale</Link>
            <Link to="/for-sale/brand/bmw">Used BMW for sale</Link>
            <Link to="/for-sale/brand/chevrolet">Used Chevrolet for sale</Link>
            <Link to="/for-sale/brand/dodge">Used Dodge for sale</Link>
          </div> */}
          <div className="FooterNavGroup">
            <strong>Help</strong>
            <Link to="/buyer-faq">Buyer FAQ</Link>
            <Link to="/seller-faq">Seller FAQ</Link>
            <br />
            <strong>Social Media</strong>
            <a href="https://www.facebook.com/carableus" rel="nofollow" target="_blank">Find us on Facebook</a>
            <a href="https://twitter.com/carableus" rel="nofollow" target="_blank">Find us on Twitter</a>
            <a href="https://www.instagram.com/carable_us" rel="nofollow" target="_blank">Find us on Instagram</a>
          </div>
          <div className="FooterNavGroup">
            <strong>Contact us</strong>
            Email: <a className="ContactLink" href="mailto:info@carable.com">info@carable.com</a>
          </div>
        </div>
      </Wrapper>
    </div>
    <div className="FooterPosterior">
      <Wrapper v={2}>
        <Grid v={2}>
          <GridItem v={2} size={12}>
            <Logo />
            Copyright © 2018 Carable Inc<br />
            1601 Elm St, Dallas TX 75201
          </GridItem>
        </Grid>
      </Wrapper>
    </div>
  </div>
)

export default Footer
