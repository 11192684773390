import { titleCase } from 'services/helpers'

export function organization() {
  return {
    "@context": "http://schema.org",
    '@type': 'Organization',
    'name': 'Carable',
    'description': 'Carable is the person-to-person used car marketplace for buying and selling cars you can count on.',
    'url': 'https://www.carable.com',
    'logo': 'https://platscus.blob.core.windows.net/assets/Logo.svg',
    'sameAs': [
      'https://www.facebook.com/carableus',
      'https://www.twitter.com/carableus',
      'https://www.linkedin.com/company/carable-inc',
    ]
  }
}

export function car(vehicleInfo, saleContractId, location) {
  const link = `${vehicleInfo.make}-${vehicleInfo.model}-${vehicleInfo.year}-${saleContractId}`.replace(' ', '-')
  const makeString = titleCase(vehicleInfo.make.replace('_', ' '))
  const modelString = titleCase(vehicleInfo.model.replace('_', ' '))

  return {
    "@context": "http://schema.org",
    "@graph": [
      {
        "@type": "Car",
        "name": `${vehicleInfo.year} ${makeString} ${modelString} for sale`,
        "url": `https://carable.com/for-sale/${link}`,
        "model": `${vehicleInfo.year} ${makeString} ${modelString}`,
        "manufacturer": [{
          '@type': 'Organization',
          'name': `${makeString}`
        }],
        "productId": `${saleContractId}`
      },
      {
        "@type": "Place",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": location.city,
          "addressRegion": location.state
        }
      }
    ]
  }
}

export function organizationWithLocation(location) {
  return {
    "@context": "http://schema.org",
    "@graph": [
      {
        '@type': 'Organization',
        'name': 'Carable',
        'description': 'Carable is the person-to-person used car marketplace for buying and selling cars you can count on.',
        'url': 'https://www.carable.com',
        'logo': 'https://platscus.blob.core.windows.net/assets/Logo.svg',
        'sameAs': [
          'https://www.facebook.com/carableus',
          'https://www.twitter.com/carableus',
          'https://www.linkedin.com/company/carable-inc',
        ]
      },
      {
        "@type": "Place",
        "name": 'Carable',
        "address": {
          "@type": "PostalAddress",
          "addressLocality": location.city,
          "addressRegion": location.state
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": location.lat,
          "longitude": location.lng
        }
      }
    ]
  }
}

export default {
  organization,
  car,
  organizationWithLocation
}
