import env from 'env'
import algoliasearch from 'algoliasearch'

const COUNT_VEHICLES = 'COUNT_VEHICLES'

function countVehicles(count) {
  return {
    type: COUNT_VEHICLES,
    data: {
      count
    }
  }
}

export function getTotalVehicles() {
  return async dispatch => {
    const searchClient = algoliasearch(env.searchAppId, env.searchApiKey)
    const index = searchClient.initIndex(env.searchIndex)
    const count = await index.search({ query: '' })
    return dispatch(countVehicles(count.nbHits))
  }
}

const initialState = {
  count: 0
}

export default function valuation(state = initialState, action) {
  switch (action.type) {
    case COUNT_VEHICLES:
      return {
        ...state,
        count: action.data.count
      }
    default:
      return state
  }
}
