import req from './req'
import Cookies from 'js-cookie'
import { isObject } from './helpers.js'

function url(path) {
  const authority = window.__AUTHORITY__
  return [authority, path.replace(/^\//g, '')].join('/')
}

function requestErrors(err) {
  if ('response' in err && isObject(err.response) && 'data' in err.response) {
    console.error('Unknown error response!', err.response.data)
  } else {
    console.error('Unknown error response!', err)
  }

  return Promise.reject(err)
}
class Accounts {
  /**
  * Used to refresh tokens and check login status
  */
  get() {
    return req.create({ timeout: 20000, withCredentials: true }).get(url('/api/v2/accounts/')).then((response) => response.data).catch(requestErrors)
  }
  signin (data) {
    if (!data) throw new Error('Missing data')
    let xsrf = Cookies.get('XSRF-TOKEN')
    return req.create({ xsrf: xsrf, withCredentials: true }).post(url('/api/v2/accounts/login'), data)
      .catch(requestErrors)
  }
  impersonate (id) {
    if (!id) throw new Error('Missing id')
    let xsrf = Cookies.get('XSRF-TOKEN')
    return req.create({ xsrf: xsrf, withCredentials: true }).post(url(`/api/v2/accounts/${id}/impersonate`), {})
      .catch(requestErrors)
  }
  signInWithToken (data) {
    if (!data) throw new Error('Missing data')
    let xsrf = Cookies.get('XSRF-TOKEN')
    return req.create({ xsrf: xsrf, withCredentials: true }).post(url('/api/v2/accounts/login-with-token'), data)
      .catch(requestErrors)
  }
  token () {
    let xsrf = Cookies.get('XSRF-TOKEN')
    return req.create({ xsrf: xsrf, withCredentials: true }).post(url('/api/v2/accounts/token'))
      .catch(requestErrors)
  }
  emailExists (email) {
    let xsrf = Cookies.get('XSRF-TOKEN')
    return req.create({ timeout: 20000, xsrf: xsrf, withCredentials: true }).post(url('/api/v2/accounts/email-exists?email=' + encodeURIComponent(email)))
      .then(r => {
        switch (r.data) {
          case 'Found': return 'Exists'
          case 'NotFound': return 'NotExists'
        }
        return 'Unknown'
      })
      .catch(requestErrors)
  }
  signout () {
    let xsrf = Cookies.get('XSRF-TOKEN')
    return req.create({ xsrf: xsrf, withCredentials: true }).post(url('/api/v2/accounts/logout'), { logoutId: '' })
      .catch(requestErrors)
  }

  signUp (data) {
    if (!data) throw new Error('Missing data')
    let xsrf = Cookies.get('XSRF-TOKEN')
    return req.create({ timeout: 20000, xsrf: xsrf, withCredentials: true }).post(url('/api/v2/accounts/sign-up'), data)
      .catch(requestErrors)
  }
}

export default Accounts
