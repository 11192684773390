import { asyncComponent } from '@carable/after'

import Home from 'routes/Home/components/HomeView'
// import BuyRedirect from 'routes/redirects/BuyRedirect'
// import BuyDetailRedirect from 'routes/redirects/BuyDetailRedirect'
// import BookRetarget from 'routes/BookRetarget/components'
// import ReportRedirect from 'routes/ReportRedirect/components'
import NotFound from 'routes/NotFound/components'

// Handles failed chunks loading due to user running an older version of the site
const loadingErrorHandler = () => {
  window.location.reload()
  return null
}

export default [
  {
    path: '/',
    exact: true,
    component: Home
  },
  {
    path: '/buyer-faq',
    exact: true,
    component: asyncComponent({
      loader: () => import('routes/BuyerFAQ/components'),
      ErrorComponent: loadingErrorHandler
    }),
  },
  {
    path: '/seller-faq',
    exact: true,
    component: asyncComponent({
      loader: () => import('routes/SellerFAQ/components'),
      ErrorComponent: loadingErrorHandler
    }),
  },
  {
    path: '/how-it-works',
    exact: true,
    component: asyncComponent({
      loader: () => import('routes/HowItWorks/components'),
      ErrorComponent: loadingErrorHandler
    }),
  },
  // {
  //   path: '/for-sale',
  //   exact: true,
  //   component: asyncComponent({
  //     loader: () => import('routes/Buy/components/BuyView'),
  //     ErrorComponent: loadingErrorHandler
  //   }),
  // },
  // {
  //   path: '/for-sale/location/:location',
  //   exact: true,
  //   component: asyncComponent({
  //     loader: () => import('routes/LocationLanding/components'),
  //     ErrorComponent: loadingErrorHandler
  //   }),
  // },
  // {
  //   path: '/for-sale/brand/:make',
  //   exact: true,
  //   component: asyncComponent({
  //     loader: () => import('routes/BrandLanding/components'),
  //     ErrorComponent: loadingErrorHandler
  //   }),
  // },
  // {
  //   path: '/for-sale/:vehicle-:saleContractId(SC_?[a-f\\d]+)',
  //   component: asyncComponent({
  //     loader: () => import('routes/BuyDetail/components/BuyDetail'),
  //     ErrorComponent: loadingErrorHandler
  //   }),
  // },
  // {
  //   path: '/sell',
  //   exact: true,
  //   component: asyncComponent({
  //     loader: () => import('routes/Sell/components/SellWrapper'),
  //     ErrorComponent: loadingErrorHandler
  //   }),
  // },
  // {
  //   path: '/sell/book/:vehicleInfoId',
  //   component: asyncComponent({
  //     loader: () => import('routes/Book/components'),
  //     ErrorComponent: loadingErrorHandler
  //   }),
  // },
  // {
  //   path: '/login',
  //   exact: true,
  //   component: asyncComponent({
  //     loader: () => import('routes/Login/components'),
  //     ErrorComponent: loadingErrorHandler
  //   }),
  // },
  // {
  //   path: '/logout',
  //   exact: true,
  //   component: asyncComponent({
  //     loader: () => import('routes/Logout/components'),
  //     ErrorComponent: loadingErrorHandler
  //   }),
  // },
  // {
  //   path: '/register',
  //   exact: true,
  //   component: asyncComponent({
  //     loader: () => import('routes/Register/components'),
  //     ErrorComponent: loadingErrorHandler
  //   }),
  // },
  // {
  //   path: '/reset-password/:userId',
  //   exact: true,
  //   component: asyncComponent({
  //     loader: () => import('routes/ResetPassword/components'),
  //     ErrorComponent: loadingErrorHandler
  //   }),
  // },
  // {
  //   path: '/my-carable',
  //   component: asyncComponent({
  //     loader: () => import('routes/MyCarable/components'),
  //     ErrorComponent: loadingErrorHandler
  //   }),
  // },
  // {
  //   path: '/account',
  //   component: asyncComponent({
  //     loader: () => import('routes/Account/components'),
  //     ErrorComponent: loadingErrorHandler
  //   }),
  // },
  {
    path: '/terms',
    exact: true,
    component: asyncComponent({
      loader: () => import('routes/Terms/components'),
      ErrorComponent: loadingErrorHandler
    }),
  },
  {
    path: '/privacy',
    exact: true,
    component: asyncComponent({
      loader: () => import('routes/Privacy/components'),
      ErrorComponent: loadingErrorHandler
    }),
  },
  // {
  //   path: '/user-agreement',
  //   exact: true,
  //   component: asyncComponent({
  //     loader: () => import('routes/UserAgreement/components'),
  //     ErrorComponent: loadingErrorHandler
  //   }),
  // },
  // {
  //   path: '/collections/:slug',
  //   exact: true,
  //   component: asyncComponent({
  //     loader: () => import('routes/Collections/components'),
  //     ErrorComponent: loadingErrorHandler
  //   }),
  // },
  // {
  //   path: '/reports/:reportId',
  //   exact: true,
  //   component: asyncComponent({
  //     loader: () => import('routes/Reports/components'),
  //     ErrorComponent: loadingErrorHandler
  //   })
  // },
  // {
  //   path: '/login/token',
  //   exact: true,
  //   component: asyncComponent({
  //     loader: () => import('routes/TokenLogin/components'),
  //     ErrorComponent: loadingErrorHandler
  //   }),
  // },
  // {
  //   path: '/login/impersonate',
  //   exact: true,
  //   component: asyncComponent({
  //     loader: () => import('routes/Impersonate/components'),
  //     ErrorComponent: loadingErrorHandler
  //   }),
  // },
  // {
  //   path: '/refer',
  //   exact: true,
  //   component: asyncComponent({
  //     loader: () => import('routes/ReferralLanding/components'),
  //     ErrorComponent: loadingErrorHandler
  //   })
  // },

  // // Redirects
  // {
  //   path: '/refer/:refId',
  //   exact: true,
  //   component: asyncComponent({
  //     loader: () => import('routes/ReferralRedirect/components'),
  //     ErrorComponent: loadingErrorHandler
  //   }),
  // },
  // {
  //   path: '/sell/book',
  //   exact: true,
  //   component: BookRetarget
  // },
  // {
  //   path: '/buy/:saleContractId',
  //   exact: true,
  //   component: BuyDetailRedirect
  // },
  // {
  //   path: '/buy',
  //   exact: true,
  //   component: BuyRedirect
  // },
  {
    path: '/about',
    exact: true,
    component: asyncComponent({
      loader: () => import('routes/About/components'),
      ErrorComponent: loadingErrorHandler
    }),
  },
  {
    path: '/investors',
    exact: true,
    component: asyncComponent({
      loader: () => import('routes/Investors/components'),
      ErrorComponent: loadingErrorHandler
    }),
  },
  // {
  //   path: '/carfax/:vehicleInfoId',
  //   exact: true,
  //   component: ReportRedirect
  // },
  // 404
  {
    path: '**',
    component: NotFound,
  },
]
