import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './Header.scss'
import Logo from 'components/Logo'
import Grid from 'components/Grid'
import GridItem from 'components/GridItem'
import Wrapper from 'components/Wrapper'
// import VehicleSearch from './VehicleSearch'
// import VehicleLiveSearch from './VehicleLiveSearch'
// import UserActions from './UserActions'
import Navigation from './Navigation'

export class Header extends React.Component {
  static defaultProps = {
    fixed: true,
    liveSearch: false,
    vehicleCount: 0,
    hideSearch: false
  }

  static propTypes = {
    fixed: PropTypes.bool,
    liveSearch: PropTypes.bool,
    // vehicleCount: PropTypes.number,
    hideSearch: PropTypes.bool
  }

  constructor(props) {
    super(props)

    this.state = {
      scrolled: false
    }
  }

  render() {
    const { fixed, liveSearch, hideSearch } = this.props
    // const placeholder = vehicleCount
    //   ? `Type to search ${vehicleCount} cars`
    //   : 'Type to search for a vehicle...'

    // let searchBar = <div className="VehicleSearchPlaceholder" />

    // if (!hideSearch) {
    //   searchBar = liveSearch
    //     ? <VehicleLiveSearch placeholder={placeholder} />
    //     : <VehicleSearch placeholder={placeholder} />
    // }

    return (
      <header className="Header" data-fixed={fixed} data-search={liveSearch} data-hide-search={hideSearch}>
        <div className="HeaderShadow" />
        <div className="HeaderContent">
          <Wrapper v={2}>
            <Grid v={2}>
              <GridItem size={12} v={2}>
                <div className="HeaderContentWrapper">
                  <div className="BoxLogo">
                    <Logo />
                  </div>
                  {/* {searchBar} */}
                  <Navigation />
                  {/* <UserActions /> */}
                </div>
              </GridItem>
            </Grid>
          </Wrapper>
        </div>
      </header>
    )
  }
}

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    vehicleCount: state.vehicles.count
  }
}

export default connect(mapStateToProps)(Header)
