import Cookies from 'js-cookie'
import env from 'env'
import moment from 'moment'
import authReq from 'services/authReq'

export const OFFER_STATUS_ACCEPTED = 'Accepted'
export const OFFER_STATUS_PLACED = 'Placed'
export const OFFER_STATUS_DECLINED = 'Declined'

export const CONTRACT_STATUS_IN_ORDER = 'InOrder'
export const CONTRACT_STATUS_COMPLETED = 'Completed'
export const CONTRACT_STATUS_CREATED = 'Created'

export const OFFER_ERROR_NOT_LOGGED_IN = 'RequiresUser'
export const OFFER_ERROR_TOO_LOW = 'NoLowballOffers'
export const OFFER_ERROR_HAS_BUYER = 'HasBuyer'
export const OFFER_ERROR_OWN_SALE = 'OfferOnYourOwnSale'

export const FIXEDPRICE_ERROR_NOT_LOGGED_IN = 'RequiresUser'

class SaleService {
  getSale(saleContractId) {
    const url = `${env.saleAPI}/api/v3/sales/${saleContractId}`
    return authReq().get(url).then(resp => resp.data)
  }

  my(includeFixedPrices = true) {
    const params = {
      includeFixedPrices
    }
    const url = `${env.saleAPI}/api/v3/my`
    return authReq({ retry: true }).get(url, { params }).then(resp => resp.data)
  }

  async createFixedPrice({ saleContractId, price }) {
    const url = `${env.saleAPI}/api/v3/salecontracts/fixedprices`

    try {
      const data = {
        saleContractId: saleContractId,
        startDate: moment().toDate(),
        endDate: moment().add(1, 'years').toDate(),
        price: price
      }

      const resp = await authReq().post(url, data).then(resp => resp.data)

      return resp
    } catch (err) {
      const errStatus = err.response && err.response.status
      const errObj = err.response && err.response.data

      if (errStatus === 401) {
        throw new Error(FIXEDPRICE_ERROR_NOT_LOGGED_IN)
      }

      if (errObj && errObj && Array.isArray(errObj) && errObj.length > 0) {
        throw new Error(errObj.shift().code)
      }
    }
  }

  async updateFixedPrice({ fixedPriceId, price }) {
    const url = `${env.saleAPI}/api/v3/salecontracts/fixedprices/${fixedPriceId}`

    try {
      const data = {
        price: price,
        startDate: moment().toDate(),
        endDate: moment().add(1, 'years').toDate()
      }

      const resp = await authReq().put(url, data).then(resp => resp.data)
      console.log(data)
      return resp
    } catch (err) {
      const errStatus = err.response && err.response.status
      const errObj = err.response && err.response.data

      if (errStatus === 401) {
        throw new Error(FIXEDPRICE_ERROR_NOT_LOGGED_IN)
      }

      if (errObj && errObj && Array.isArray(errObj) && errObj.length > 0) {
        throw new Error(errObj.shift().code)
      }
    }
  }

  async makeOffer(fixedPriceId, amount) {
    const url = `${env.saleAPI}/api/v3/salecontracts/fixedprices/${fixedPriceId}/offers`
    const data = {
      'amount' : amount
    }

    try {
      const resp = await authReq().post(url, data).then(resp => resp.data)

      return resp
    } catch (err) {
      const errStatus = err.response && err.response.status
      const errObj = err.response && err.response.data

      if (errStatus === 401) {
        throw new Error(OFFER_ERROR_NOT_LOGGED_IN)
      }

      if (errObj && errObj && Array.isArray(errObj) && errObj.length > 0) {
        throw new Error(errObj.shift().code)
      }
    }
  }

  acceptOffer(offerId) {
    const url = `${env.saleAPI}/api/v3/salecontracts/fixedprices/offers/${offerId}/accept`
    return authReq().put(url).then(resp => resp.data)
  }

  declineOffer(offerId) {
    const url = `${env.saleAPI}/api/v3/salecontracts/fixedprices/offers/${offerId}/decline`
    return authReq().put(url).then(resp => resp.data)
  }

  withdrawOffer(offerId) {
    const url = `${env.saleAPI}/api/v3/salecontracts/fixedprices/offers/${offerId}/withdraw`
    return authReq().put(url).then(resp => resp.data)
  }

  updateSellerMessage(saleContractId, message, hasLien) {
    const url = `${env.saleAPI}/api/v2/my/sales/${saleContractId}/description`
    return authReq().put(url, { description: message, hasLien })
  }

  // Referral endpoints
  registerReferralId() {
    const refId = Cookies.get('referral_id')
    if (!refId) {
      return
    }

    authReq().put(`${env.saleAPI}/api/v3/referrals/token/use`, { token: refId }).then((resp) => {
      return resp
    }).catch((error) => {
      console.log(error)
    })
  }

  async getReferralData() {
    try {
      const url = `${env.saleAPI}/api/v3/referrals/my`
      const resp = await authReq().get(url).then(resp => resp.data)
      return resp
    } catch (err) {
      return {
        usersReferred: 0,
        referredByToken: null,
        myTokens: []
      }
    }
  }

  createReferralToken() {
    const url = `${env.saleAPI}/api/v3/referrals/token`
    return authReq().post(url, {}).then(resp => resp.data)
  }

  referralSendTo(data) {
    const url = `${env.saleAPI}/api/v3/referrals/send-to`
    return authReq().post(url, data).then(resp => resp.data)
  }
}

export default new SaleService()
