import React from 'react'
import Button from 'components/Button'
import PageLayout from 'layouts/PageLayout'
import Wrapper from 'components/Wrapper'
import Grid from 'components/Grid'
import GridItem from 'components/GridItem'
import PhoneNumber from 'components/PhoneNumber'
import './NotFound.scss'

const notFound = () => {
  return (
    <div>
      <PageLayout>
        <div className="NotFoundContainer">
          <Wrapper>
            <Grid type="fixed">
              <GridItem size="12">
                <div className="NotFoundText">
                  <h1 className="Title">Oops!</h1>
                  <h3>The page you were looking for can't be found</h3>
                  <p>We're sorry about that</p>
                </div>
                <div className="Buttons">
                  <Button link="/" size="mediumthin" type="primary" label="Back to homepage" />
                  <PhoneNumber
                    data-size="mediumthin"
                    data-type="tertiary"
                    label="Call us: %n"
                  />
                </div>
              </GridItem>
            </Grid>
          </Wrapper>
        </div>
      </PageLayout>
    </div>
  )
}
export default notFound
