import React from 'react'
import PropTypes from 'prop-types'

export default class PhoneNumber extends React.Component {
  static propTypes = {
    icon: PropTypes.bool,
    label: PropTypes.string
  }

  static defaultProps = {
    icon: false,
    label: '%n'
  }

  constructor(props) {
    super(props)

    this.aRef = React.createRef()
  }

  shouldComponentUpdate() {
    return false
  }

  getExistingHtml() {
    if (!this.aRef.current) {
      return
    }
    return this.aRef.current.innerHTML
  }

  renderLink() {
    const { icon, label, ...props } = this.props
    const tel = '469-730-4455'

    return (
      <a {...props} href="tel:+14697304455" ref={this.aRef}>{icon && <i className="ion-ios-telephone" />} {label.replace('%n', tel)}</a>
    )
  }

  render() {
    const html = this.getExistingHtml()

    if (html) {
      return <React.Fragment dangerouslySetInnerHTML={{ __html: html }} />
    }

    return (
      <React.Fragment></React.Fragment>
    )
  }
}
