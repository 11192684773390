import authService from 'services/authService'
import getSbaTrackingId from 'utils/getSbaTrackingId'
import { postSbaTrackingId } from 'services/paymentService'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const GET_USER_INFO = 'GET_USER_INFO'
export const IS_LOGGED_IN = 'IS_LOGGED_IN'
export const LOGIN_STATUS_REQUEST = 'LOGIN_STATUS_REQUEST'
export const UPDATE_BASIC_INFORMATION = 'UPDATE_BASIC_INFORMATION'
export const UPDATE_USER_ADDRESS = 'UPDATE_USER_ADDRESS'
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE'
export const IMPERSONATE_FAILURE = 'IMPERSONATE_FAILURE'
export const IMPERSONATE_SUCCESS = 'IMPERSONATE_SUCCESS'

export function requestLogin() {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false
  }
}

export function receiveLogin(info) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    info
  }
}

export function loginError() {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    loginError: true
  }
}

function impersonateError() {
  return {
    type: IMPERSONATE_FAILURE
  }
}

function impersonateSuccess() {
  return {
    type: IMPERSONATE_SUCCESS
  }
}

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true
  }
}

function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false
  }
}

function receiveUserInfo(info) {
  return {
    type: GET_USER_INFO,
    isAuthenticated: true,
    info
  }
}

function receiveBasicInformation(info) {
  return {
    type: UPDATE_BASIC_INFORMATION,
    info
  }
}

function receiveUserAddress(address) {
  return {
    type: UPDATE_USER_ADDRESS,
    address
  }
}

function requestLoginStatus() {
  return {
    type: LOGIN_STATUS_REQUEST,
    isFetching: true
  }
}

function setLoginStatus(loggedIn) {
  return {
    type: IS_LOGGED_IN,
    isFetching: false,
    isAuthenticated: loggedIn
  }
}

function requestSignUp() {
  return {
    type: SIGNUP_REQUEST,
    isFetching: true,
    signupError: false
  }
}

function receiveSignup(info) {
  return {
    type: SIGNUP_SUCCESS,
    isAuthenticated: true,
    isFetching: false,
    signupError: false,
    info
  }
}

function signupError(error) {
  return {
    type: SIGNUP_FAILURE,
    isFetching: false,
    error: error,
    signupError: true
  }
}

export function loginUser(username, password, rememberMe) {
  return dispatch => {
    dispatch(requestLogin())

    return authService.signIn(username, password, rememberMe).then(() => {
      const sbaTrackingId = getSbaTrackingId()
      postSbaTrackingId(sbaTrackingId)

      return authService.getUserInfo().then(resp => dispatch(receiveLogin(resp.info)))
    }).catch(() => dispatch(loginError()))
  }
}

export function loginUserWithToken(email, token) {
  return dispatch => {
    dispatch(requestLogin())

    return authService.signInWithToken(email, token).then(() => {
      const sbaTrackingId = getSbaTrackingId()
      postSbaTrackingId(sbaTrackingId)

      return authService.getUserInfo().then(resp => dispatch(receiveLogin(resp.info)))
    }).catch(() => dispatch(loginError()))
  }
}

export function impersonateUserWithId(id) {
  return dispatch => {
    dispatch(requestLogin())

    return authService.impersonateUserWithId(id).then(() => {
      dispatch(impersonateSuccess())

      return authService.getUserInfo().then(resp => dispatch(receiveLogin(resp.info)))
    }).catch(() => dispatch(impersonateError()))
  }
}

export function logoutUser() {
  return dispatch => {
    dispatch(requestLogout())

    return authService.logout().then(() => {
      dispatch(receiveLogout())
    })
  }
}

export function getUserInfo() {
  return dispatch => {
    return authService.loggedIn().then(loggedIn => {
      if (loggedIn) {
        authService.getUserInfo().then(resp => {
          dispatch(receiveUserInfo(resp.info))
        })
      }
    })
  }
}

export function isLoggedIn() {
  return dispatch => {
    dispatch(requestLoginStatus())

    return authService.loggedIn().then(loggedIn => {
      dispatch(setLoginStatus(loggedIn))
    }).catch(() => {
      dispatch(setLoginStatus(false))
    })
  }
}

export function updateBasicInformation(data) {
  return dispatch => {
    return authService.updateUserBasicInformation(data).then(user => {
      dispatch(receiveBasicInformation(user.info))
    })
  }
}

export function updateUserAddress(data) {
  return dispatch => {
    return authService.updateUserAddress(data).then(user => {
      dispatch(receiveUserAddress(user.info.address))
    })
  }
}

export function createAccount(data) {
  return async dispatch => {
    dispatch(requestSignUp())

    try {
      await authService.signUp(data)

      const sbaTrackingId = getSbaTrackingId()
      postSbaTrackingId(sbaTrackingId)

      const user = await authService.getUserInfo()
      return dispatch(receiveSignup(user.info))
    } catch (err) {
      dispatch(signupError(err))

      throw new Error(err)
    }
  }
}

const initialState = {
  isFetching: false,
  loginError: false,
  signupError: false,
  isAuthenticated: false,
  isImpersonating: false,
  info: {
    id: '',
    address: {
      addressline: '',
      country: '',
      city: '',
      zipcode: '',
      state: ''
    },
    email: '',
    name: {
      first: '',
      last: ''
    },
    phone: ''
  }
}

export default function user(state = initialState, action) {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: action.isAuthenticated,
        info: initialState.info,
        signupError: false
      })
    case SIGNUP_SUCCESS:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        isAuthenticated: action.isAuthenticated,
        info: action.info,
        signupError: false
      })
    case SIGNUP_FAILURE:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        isAuthenticated: action.isAuthenticated,
        error: action.error,
        signupError: action.signupError
      })
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: action.isAuthenticated,
        info: initialState.info,
        loginError: false
      })
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        isAuthenticated: action.isAuthenticated,
        info: action.info,
        loginError: false
      })
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        isAuthenticated: action.isAuthenticated,
        loginError: action.loginError
      })
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        isAuthenticated: action.isAuthenticated,
        info: initialState.info
      })
    case GET_USER_INFO:
      return Object.assign({}, state, {
        isAuthenticated: action.isAuthenticated,
        info: action.info
      })
    case IS_LOGGED_IN:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        isAuthenticated: action.isAuthenticated
      })
    case LOGIN_STATUS_REQUEST:
      return Object.assign({}, state, {
        isFetching: action.isFetching
      })
    case IMPERSONATE_FAILURE:
      return Object.assign({}, state, {
        isImpersonating: false,
        isFetching: false
      })
    case IMPERSONATE_SUCCESS:
      return Object.assign({}, state, {
        isImpersonating: true
      })
    case UPDATE_BASIC_INFORMATION:
      return {
        ...state,
        info: {
          ...state.info,
          name: {
            first: action.info.name.first,
            last: action.info.name.last
          },
          email: action.info.email,
          phone: action.info.phoneNumber
        }
      }
    case UPDATE_USER_ADDRESS:
      return {
        ...state,
        info: {
          ...state.info,
          address: action.address
        }
      }
    default:
      return state
  }
}
