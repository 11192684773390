import saleService from 'services/saleService'

// Actions
export const SET_OFFER_AMOUNT = 'SET_OFFER_AMOUNT'
export const SET_OFFER_STATUS = 'SET_OFFER_STATUS'
export const SET_OFFER_ERROR = 'SET_OFFER_ERROR'
export const UPDATE_SIDEBAR_VIEW = 'UPDATE_SIDEBAR_VIEW'
export const SET_INPUT_FOCUS = 'SET_INPUT_FOCUS'
export const SUBMIT_OFFER = 'SUBMIT_OFFER'
export const CLEAR_OFFER_INFORMATION = 'CLEAR_OFFER_INFORMATION'
export const RECEIVE_SUCCESSFUL_OFFER = 'RECEIVE_SUCCESSFUL_OFFER'
export const UPDATE_AUCTION = 'UPDATE_AUCTION'
export const SET_USER_FLOW = 'SET_USER_FLOW'

// States
export const USER_FLOW_DEFAULT = 'USER_FLOW_DEFAULT'
export const USER_FLOW_OFFER = 'USER_FLOW_OFFER'
export const USER_FLOW_FINANCING = 'USER_FLOW_FINANCING'
export const USER_FLOW_TEST_DRIVE = 'USER_FLOW_TEST_DRIVE'

// Action creators
const receiveOfferAmount = amount => {
  return {
    type: SET_OFFER_AMOUNT,
    amount
  }
}
const updateOfferStatus = status => {
  return {
    type: SET_OFFER_STATUS,
    status
  }
}

const setOfferError = error => {
  return {
    type: SET_OFFER_ERROR,
    error
  }
}

const updateSidebarView = view => {
  return {
    type: UPDATE_SIDEBAR_VIEW,
    view
  }
}

const setInputfocus = focused => {
  return {
    type: SET_INPUT_FOCUS,
    focused
  }
}

const submitOffer = () => {
  return {
    type: SUBMIT_OFFER
  }
}

const clearOfferInformation = (preserveAmount = false) => {
  return {
    type: CLEAR_OFFER_INFORMATION,
    preserveAmount
  }
}

const receiveSuccessfulOffer = () => {
  return {
    type: RECEIVE_SUCCESSFUL_OFFER
  }
}

export const setOfferAmount = amount => {
  return dispatch => dispatch(receiveOfferAmount(amount))
}

export const setOfferStatus = status => {
  return dispatch => dispatch(updateOfferStatus(status))
}

export const rejectOffer = error => {
  return dispatch => dispatch(setOfferError(error))
}

export const setSidebarView = view => {
  return dispatch => dispatch(updateSidebarView(view))
}

export const focusOfferInput = () => {
  return dispatch => dispatch(setInputfocus(true))
}

export const blurOfferInput = () => {
  return dispatch => dispatch(setInputfocus(false))
}

export const placeOffer = (fixedPriceId, amount) => {
  return async (dispatch, getState) => {
    dispatch(submitOffer())

    const resp = await saleService.makeOffer(fixedPriceId, amount)

    dispatch(receiveSuccessfulOffer())

    return resp
  }
}

export const resetOfferInput = (preserveAmount) => {
  return dispatch => dispatch(clearOfferInformation(preserveAmount))
}

export function setUserFlow(flowId) {
  return {
    type: SET_USER_FLOW,
    flowId
  }
}

export function resetUserFlow() {
  return {
    type: SET_USER_FLOW,
    flowId: USER_FLOW_DEFAULT
  }
}

// State
const initialState = {
  sidebar: {
    view: 'default'
  },
  offer: {
    amount: 0,
    error: '',
    focused: false,
    status: 'initial'
  },
  saleContract: {
    isSeller: false
  },
  userFlow: USER_FLOW_DEFAULT
}

// Reducer
export default function buyDetail(state = initialState, action) {
  switch (action.type) {
    case SET_OFFER_AMOUNT:
      return Object.assign({}, state, {
        offer: {
          ...state.offer,
          focused: true,
          amount: action.amount
        }
      })
    case SET_OFFER_STATUS:
      return Object.assign({}, state, {
        offer: {
          ...state.offer,
          status: action.status
        }
      })
    case SET_OFFER_ERROR:
      return Object.assign({}, state, {
        offer: {
          ...state.offer,
          status: 'error',
          error: action.error
        }
      })
    case SET_INPUT_FOCUS:
      return Object.assign({}, state, {
        offer: {
          ...state.offer,
          focused: action.focused
        }
      })
    case SUBMIT_OFFER:
      return Object.assign({}, state, {
        offer: {
          ...state.offer,
          error: '',
          status: 'processing'
        }
      })
    case RECEIVE_SUCCESSFUL_OFFER:
      return Object.assign({}, state, {
        offer: {
          ...state.offer,
          status: 'complete',
        }
      })
    case UPDATE_SIDEBAR_VIEW:
      return Object.assign({}, state, {
        sidebar: {
          ...state.sidebar,
          view: action.view
        }
      })
    case CLEAR_OFFER_INFORMATION:
      return Object.assign({}, state, {
        offer: {
          ...initialState.offer,
          amount: action.preserveAmount ? state.offer.amount : 0,
          error: '',
          status: 'initial'
        }
      })
    case UPDATE_AUCTION:
      return Object.assign({}, state, {
        action: {
          ...state.saleContract,
          isSeller: action.isSeller
        }
      })
    case SET_USER_FLOW:
      return Object.assign({}, state, {
        userFlow: action.flowId
      })
    default:
      return state
  }
}
