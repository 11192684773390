import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MobileVehicleSearch from 'components/MobileVehicleSearch'
import Header from './Header'
import MobileMenu from './MobileMenu'
import './MobileHeader.scss'

export class MobileHeader extends React.Component {
  static defaultProps = {
    liveSearch: false,
    loggedIn: false,
    vehicleCount: 0
  }

  static propTypes = {
    liveSearch: PropTypes.bool,
    loggedIn: PropTypes.bool,
    vehicleCount: PropTypes.number
  }

  constructor(props) {
    super(props)

    this.state = {
      navigationIsOpen: false,
    }

    this.handleNavigationToggle = this.handleNavigationToggle.bind(this)
  }

  handleNavigationToggle() {
    if (this.state.navigationIsOpen) {
      this.setState({ navigationIsOpen: false })
    } else {
      this.setState({ navigationIsOpen: true })
    }
  }
  render() {
    const { liveSearch, loggedIn, vehicleCount } = this.props
    const { navigationIsOpen } = this.state
    const placeholder = vehicleCount
      ? `Type to search ${vehicleCount} cars`
      : 'Type to search for a vehicle...'

    return (
      <div className="MobileHeader" data-active={navigationIsOpen}>
        <Header onClick={this.handleNavigationToggle} navigationIsOpen={navigationIsOpen} />
        <MobileMenu navigationIsOpen={navigationIsOpen} loggedIn={loggedIn} />
        {liveSearch && (
          <MobileVehicleSearch placeholder={placeholder} />
        )}
      </div>
    )
  }
}

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    vehicleCount: state.vehicles.count
  }
}

export default connect(mapStateToProps)(MobileHeader)
