import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './ImpersonateWarning.scss'

class ImpersonateWarning extends React.Component {
  static propTypes = {
    isImpersonating: PropTypes.bool,
    name: PropTypes.object
  }

  stopImpersonating(e) {
    e.preventDefault()
    sessionStorage.clear()
    document.location.reload()
  }

  render () {
    const { isImpersonating, name } = this.props

    if (!isImpersonating) {
      return null
    }

    return (
      <div className="ImpersonateWarning">
        <div className="content">
          WARNING: You are currently logged in as {`${name.first} ${name.last}`}. <a href="#" onClick={this.stopImpersonating}>Stop impersonating</a>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isImpersonating: state.user.isImpersonating,
    name: state.user.info.name
  }
}

export default connect(mapStateToProps)(ImpersonateWarning)
