import axios from 'axios'
import axiosRetry from 'axios-retry'
import authService from './authService'

function authReq(config) {
  config = config || {}
  config.timeout = config.timeout || 10000

  const request = axios.create({
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    ...config
  })

  if (config.retry) {
    axiosRetry(request, { retries: 2 })
  }

  // Inject the JWT token in every request. This also handles automatic
  // refreshing of expired tokens
  request.interceptors.request.use(async function(config) {
    try {
      const user = await authService.getUser()

      config.headers['Authorization'] = `Bearer ${user.id_token}`
    } catch (err) {
      if (err.message !== 'not_logged_in') {
        throw new Error(err)
      }
    }

    return config
  })

  return request
}

export default authReq
