export const vehicleInfoAPI = 'https://vehicleinfo.carable.com'
export const bookingAPI = 'https://booking.carable.com'
export const saleAPI = 'https://sale.carable.com'
export const asmPayLib = 'https://js.promisepay.com/PromisePay.js'
export const asmPayEnv = 'prod'
export const payAPI = 'https://payment.carable.com'
export const geoAPI = 'https://geo.carable.com'
export const mailAPI = 'https://mail.carable.com'
export const searchIndexRecentlyAdded = 'production_VEHICLESEARCH_recently_added'
export const searchIndexMileage = 'production_VEHICLESEARCH_mileage'
export const searchIndexCurrentPriceAsc = 'production_VEHICLESEARCH_current_price_asc'
export const searchIndexCurrentPriceDesc = 'production_VEHICLESEARCH_current_price_desc'
export const searchIndexSold = 'production_VEHICLESEARCH_sold'
export const searchIndexLocations = 'production_BOOK_LOCATIONSEARCH'
export const searchIndex = 'production_VEHICLESEARCH'
export const searchAppId = 'APUFCNKUNJ'
export const searchApiKey = 'fbf57bdb1bb08f4ba3efe849120d0a28'
export const MapboxAccessToken = 'pk.eyJ1IjoiY2FyYWJsZSIsImEiOiJjajcxeGY5a2kwNG04MnFvN3B3OHZieW81In0.MUjDxO-M8Eki9dFu6rnLMw'
export const buyerFAQIndex = 'buyer_FAQ'
export const sellerFAQIndex = 'seller_FAQ'
export const mixpanelToken = '4a6f13d74f9f4ff5cfcfb35c1c56373f'
export const firebaseApiKey = 'AIzaSyDqT8aWPFKLRDD-w88amy_MDl2A1Udbags'
export const firebaseAuthDomain = 'carable-916df.firebaseapp.com'
export const firebaseDBUrl = 'https://carable-916df.firebaseio.com'
export const webRootUrl = 'https://carable.com'
export const financeUrl = 'https://www.myautoloan.com/partner/carable.html?utm_distil=carable&source=carable&site=MAL&type=web&term=<SUB_ID>'
export const firebaseIPKey = 'AIzaSyDZnGF_wgJulTp1QCPbF5XMY8x5KzmklIY'
export const firebaseIPAuthDomain = 'carableip'
export const firebaseIPDBUrl = 'https://carableip.firebaseio.com'
export const stripePublicKey = 'pk_live_bHb8EF46JhwUKFZm5KKr1D20'
export const formspreeId = 'xodpbzam'
export const formspreeTestDriveId = 'xpzzowdb'
