import React from 'react'
import PropTypes from 'prop-types'
import './Button.scss'
import { Link } from 'react-router-dom'

export const Button = ({ description, icon, label, size, type, link, external, ...props }) => {
  const Component = (link && !external) ? Link : 'a'

  return (
    <Component
      className="Button"
      to={link}
      data-has-icon={!!icon}
      data-size={size}
      data-type={type}
      {...props}
    >
      {icon && (
        <i className={`${icon} ButtonIcon`} />
      )
      }
      {label}
      {description && (
        <div className="ButtonDescription">{description}</div>
      )}
    </Component>
  )
}

Button.defaultProps = {
  link: '',
  external: false,
  description: ''
}

Button.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  link: PropTypes.string,
  external: PropTypes.bool,
  target: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'smallplus', 'medium', 'mediumthin', 'large']),
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'secondary-inverse',
    'tertiary',
    'rounded',
    'facebook',
    'twitter',
    'gradient',
    'primary-teal'
  ]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.string
}

export default Button
