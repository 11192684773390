import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

// Logos
import LogoBlackBook from './logo-black-book.png'
import LogoCarfax from './logo-carfax.png'
import LogoFirestone from './logo-firestone.png'
import LogoCentury from './logo-century.png'
import LogoSpringboard from '../../assets/mal_logo_2015-color.svg'
import './CarableValueProposition.scss'

export const CarableValueProposition = ({ hidden, handleHide }) => {
  const sliderSettings = {
    arrows: false,
    className: 'CardCarousel',
    centerMode: true,
    customPaging: (i) => {
      return <a className="Dot" />
    },
    dots: true,
    infinite: false,
    slidesToShow: 1
  }

  const stepData = [
    {
      logo: LogoBlackBook,
      link: 'https://www.blackbook.com/',
      text: (
        <div className="Text">
          <p>Valuation from<br />Black Book</p>
        </div>
      )
    },
    {
      logo: LogoCarfax,
      link: 'https://www.carfax.com/',
      text: (
        <div className="Text">
          <p>History report<br />from Carfax</p>
        </div>
      )
    },
    {
      logo: LogoFirestone,
      link: 'https://www.firestonecompleteautocare.com/',
      text: (
        <div className="Text">
          <p>241 point inspection report from<br />Firestone Complete Autocare</p>
        </div>
      )
    },
    {
      logo: LogoCentury,
      link: 'https://www.centuryservicecorp.com/',
      text: (
        <div className="Text">
          <p>3k/3-month limited<br />warranty from Mapfre</p>
        </div>
      )
    },
    {
      logo: LogoSpringboard,
      link: 'https://www.myAutoloan.com/',
      text: (
        <div className="Text">
          <p>Opportunity for car financing<br />from myAutoloan.com</p>
        </div>
      ),
      additionalStyle: { 'backgroundSize': '200px', 'backgroundPosition': 'center' }
    }
  ]

  return (
    <div className="CarableValueProposition" data-hidden={hidden}>
      <div className="CarableValuePropositionHead">
        <h2>Every Carable car comes with</h2>
      </div>
      <div className="CarableValuePropositionBody">
        {
          stepData.map((step, i) => (
            <div className="CarableValuePropositionBodyStep" key={`step-${i}`}>
              {step.text}
              <div className="CarableValuePropositionBodyStepLogo" style={{ backgroundImage: 'url(' + step.logo + ')', ...step.additionalStyle }} />
            </div>
          ))
        }
      </div>
      <div className="MobileSlider">
        <Slider {...sliderSettings}>
          {
            stepData.map((step, i) => (
              <div className="CarableValuePropositionMobileStep" key={`mobile-slider-${i}`}>
                {step.text}
                <a href={step.link} rel="nofollow" target="_blank">
                  <div className="CarableValuePropositionMobileStepLogo" style={{ backgroundImage: 'url(' + step.logo + ')', ...step.additionalStyle }} />
                </a>
              </div>
            ))
          }
        </Slider>
      </div>
      <div className="CarableValuePropositionFoot">
        <h4>Giving transparency and trust to the private car transaction</h4>
      </div>
    </div>
  )
}

export default CarableValueProposition

CarableValueProposition.propTypes = {
  hidden: PropTypes.bool,
  handleHide: PropTypes.func
}
