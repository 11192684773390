import React from 'react'
import PropTypes from 'prop-types'
import './GridItem.scss'

export const GridItem = ({ children, v, size, gutter }) => {
  let className
  if (v !== 2) {
    className = 'GridItem GridItem--' + size
  } else {
    className = 'GridItem'
  }

  return (
    <div className={className} data-size={size} data-v={v}>
      {children}
    </div>
  )
}

export default GridItem

GridItem.defaultProps = {
  size: 12,
  gutter: 100
}

GridItem.propTypes = {
  size: PropTypes.any.isRequired, // FIXME Should only allow true numbers
  v: PropTypes.number,
  gutter: PropTypes.number,
  children: PropTypes.node
}
