import React from 'react'
import PropTypes from 'prop-types'
import './Header.scss'

import Logo from 'components/Logo'

export const Header = (props) => (
  <div className="Header" data-active={props.navigationIsOpen}>
    <div onClick={props.onClick} data-active={props.navigationIsOpen} className="MenuToggle">
      <i className="ion-navicon" />
      <i className="ion-close" />
    </div>
    <Logo />
    <span className="Spacing" />
  </div>
)

export default Header

Header.propTypes = {
  navigationIsOpen: PropTypes.bool,
  onClick: PropTypes.func
}
