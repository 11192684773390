import env from 'env'
import req from 'services/req'
import authReq from 'services/authReq'

class VehicleInfoService {
  get(vehicleInfoId) {
    const url = `${env.vehicleInfoAPI}/api/v3/public/${vehicleInfoId}/productdata`
    return authReq({ retry: true }).get(url).then(resp => resp.data)
  }

  query(vehicleInfoIds) {
    const url = `${env.vehicleInfoAPI}/api/v3/public/query/productdata`
    return authReq({ retry: true }).post(url, vehicleInfoIds).then(resp => resp.data)
  }

  getCarfaxReport(vehicleInfoId) {
    const url = `${env.vehicleInfoAPI}/api/v3/public/${vehicleInfoId}/report`
    return req.create().get(url)
  }

  getYears() {
    const url = `${env.vehicleInfoAPI}/api/v1/Meta/years`
    return req.create().get(url).then(resp => resp.data)
  }

  getMakesByYear(queryData) {
    const url = `${env.vehicleInfoAPI}/api/v1/Meta/makes/years/${queryData.year}`
    return req.create().get(url).then(resp => resp.data)
  }

  getModelsByMake(queryData) {
    const url = `${env.vehicleInfoAPI}/api/v1/Meta/models/years/${queryData.year}/makes/${queryData.make}`
    return req.create().get(url).then(resp => resp.data)
  }

  getTrimsByModel(queryData) {
    const url = `${env.vehicleInfoAPI}/api/v1/Meta/trims/years/${queryData.year}/makes/${queryData.make}/models/${queryData.model}`
    return req.create().get(url).then(resp => resp.data)
  }

  getOptionsByTrim(queryData) {
    // We have to send something in each url segment. The value "null" will be transformed into null on the backend.
    const series = (queryData.series === "") ? "null" : queryData.series
    const style = (queryData.style === "") ? "null" : queryData.style

    const url = `${env.vehicleInfoAPI}/api/v1/Meta/options/years/${queryData.year}/makes/${queryData.make}/models/${queryData.model}/series/${encodeURIComponent(series)}/style/${encodeURIComponent(style)}/`
    return req.create().get(url).then(resp => resp.data)
  }

  createQuickValuation(query) {
    const url = `${env.vehicleInfoAPI}/api/v3/quickvaluations`
    return req.create().post(url, query).then(resp => resp.data)
  }

  getInspectionReport(reportId) {
    const url = `${env.vehicleInfoAPI}/api/v1/Public/${reportId}/inspectionreports`
    return req.create().get(url).then(resp => resp.data)
  }
}

export default new VehicleInfoService()
