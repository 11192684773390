import React from 'react'
import PropTypes from 'prop-types'
import Header from 'components/Header'
import Footer from 'components/Footer'
import MobileHeader from 'components/MobileHeader'
// import MobileFooter from 'components/MobileFooter'
import ImpersonateWarning from 'components/ImpersonateWarning'

import './PageLayout.scss'

export const PageLayout = ({ children, hasSearch, hideSearch }) => (
  <div>
    <ImpersonateWarning />
    <MobileHeader liveSearch={hasSearch} />
    <Header type={'default'} liveSearch={hasSearch} hideSearch={hideSearch} />
    <div className="PageLayout" data-search={hasSearch}>
      {children}
    </div>
    <Footer />
    {/* <MobileFooter /> */}
  </div>
)

PageLayout.propTypes = {
  children: PropTypes.node,
  hasSearch: PropTypes.bool,
  hideSearch: PropTypes.bool
}

PageLayout.defaultProps = {
  hasSearch: false,
  hideSearch: false
}

export default PageLayout
