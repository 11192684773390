import axios from 'axios'

class Requests {
  create(obj) {
    if (!obj) {
      obj = {}
    }
    const timeout = 'timeout' in obj ? obj.timeout : 10000
    const xsrf = 'xsrf' in obj ? obj.xsrf : null
    const jwt = 'jwt' in obj ? obj.jwt : null
    const withCredentials = obj.withCredentials || false
    let headers = {
      'Accept': 'application/json',
      'Content-type': 'application/json'
    }

    if (xsrf) {
      headers['X-XSRF-TOKEN'] = xsrf
    }
    if (jwt) {
      headers['Authorization'] = 'Bearer ' + jwt
    }
    return axios.create({
      timeout: timeout,
      withCredentials: withCredentials,
      headers: headers
    })
  }
}

export default new Requests()
