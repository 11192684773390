export const SET_REFERRAL_INFO = 'SET_REFERRAL_INFO'
export const RESET_REFERRAL_INFO = 'RESET_REFERRAL_INFO'

function referralInfo(info) {
  return {
    type: SET_REFERRAL_INFO,
    info
  }
}

function reset() {
  return {
    type: RESET_REFERRAL_INFO
  }
}

export const setReferralInfo = (info) => {
  return dispatch => dispatch(referralInfo(info))
}

export const resetReferralInfo = () => {
  return dispatch => dispatch(reset())
}

const initialState = {
  showSignup: false,
  isPrefilled: false,
  userInfo: {
    email: '',
    firstName: '',
    lastName: ''
  }
}

export default function user(state = initialState, action) {
  switch (action.type) {
    case SET_REFERRAL_INFO:
      const { email, firstName, lastName, referrer } = action.info

      return Object.assign({}, state, {
        showSignup: true,
        isPrefilled: !!email,
        referrer: referrer,
        userInfo: {
          ...state.userInfo,
          email: email,
          firstName: firstName,
          lastName: lastName
        }
      })
    case RESET_REFERRAL_INFO:
      return Object.assign({}, initialState)
    default:
      return state
  }
}
