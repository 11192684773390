import React from 'react'
import PropTypes from 'prop-types'
import './Wrapper.scss'

export const Wrapper = ({ children, v }) => (
  <div className="Wrapper" data-v={v}>
    {children}
  </div>
)

export default Wrapper

Wrapper.defaultProps = {
  v: 1
}

Wrapper.propTypes = {
  children: PropTypes.node,
  v: PropTypes.number
}
