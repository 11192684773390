import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getUserInfo, impersonateUserWithId, isLoggedIn } from 'store/user'
import { getTotalVehicles } from 'store/vehicles'
import { experimentDebugger, mixpanelHelper } from 'react-ab-test'
import ReactGA from 'react-ga'
import Helmet from 'react-helmet'

class App extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    getTotalVehicles: PropTypes.func,
    isLoggedIn: PropTypes.func,
  }

  componentDidMount() {
    experimentDebugger.enable()
    mixpanelHelper.enable()

    if (typeof document !== 'undefined') {
      setTimeout(() => {
        ReactGA.event({
          category: 'User',
          action: 'User is considered engaged'
        })
      }, 2500)
    }

    this.props.getTotalVehicles()
    this.checkLoginStatus()
  }

  componentWillUnmount() {
    if (typeof document !== 'undefined') {
      experimentDebugger.disable()
      mixpanelHelper.disable()
    }
  }

  async checkLoginStatus() {
    const { getUserInfo, impersonateUserWithId, isLoggedIn } = this.props

    // Check login status of original user
    await isLoggedIn()

    if (sessionStorage && sessionStorage.getItem('impersonate')) {
      const userId = sessionStorage.getItem('impersonate')

      // Impersonate and get user info
      await impersonateUserWithId(userId)
    } else {
      // Get original user info
      getUserInfo()
    }
  }

  isEmpty(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false
      }
    }
    return true
  }

  render () {
    const { children } = this.props

    return (
      <div>
        <Helmet>
          <title>Carable.com | Safer to Buy, Easier to Sell</title>
          <meta name="description" content="The only person-to-person marketplace for buying and selling used cars. 3rd Party Inspections. Limited Warranty. Financing." />
          <meta name="keywords" content="buy used car, sell used car, used cars for sale, used cars" />
        </Helmet>
        {children}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user.isAuthenticated
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getTotalVehicles,
    getUserInfo,
    impersonateUserWithId,
    isLoggedIn,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
