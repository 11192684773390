import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { ensureReady, After } from '@carable/after'
import { Provider } from 'react-redux'
import { ScrollContext } from 'react-router-scroll-4'
import App from 'containers/App'
import createStore from 'store/createStore'
import history from 'utils/history'
import mixpanel from 'mixpanel-browser'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import * as Sentry from '@sentry/browser'
import env from 'env'
import routes from './routes'
import 'styles/main.scss'

const shouldUpdateScroll = (prevRouterProps, { location, history }) => {
  return true
}

const MOUNT_NODE = document.getElementById('root')

ensureReady(routes).then(data => {
  const store = createStore(window.__PRELOADED_STATE__)

  mixpanel.init(env.mixpanelToken)

  // React A/B test lib needs mixpanel to be global
  window.mixpanel = mixpanel

  // Google Analytics
  ReactGA.initialize('UA-37866200-8')

  // Facebook
  ReactPixel.init('2002508056429823')

  // We have to listen to history changes to trigger page views in GA and Facebook
  history.listen((location, action) => {
    ReactGA.set({
      page: location.pathname
    })
    ReactGA.pageview(location.pathname)

    // Trigger Facebookviews
    ReactPixel.pageView()
  })

  const renderError = (err) => {
    const RedBox = require('redbox-react').default

    ReactDOM.render(
      <RedBox error={err} />,
      MOUNT_NODE
    )
  }

  const renderApp = () => {
    return ReactDOM.hydrate(
      <Provider store={store} key={Math.random()}>
        <App>
          <Router history={history}>
            <ScrollContext shouldUpdateScroll={shouldUpdateScroll}>
              <After data={data} routes={routes} />
            </ScrollContext>
          </Router>
        </App>
      </Provider>,
      MOUNT_NODE
    )
  }

  if (!__DEV__) {
    Sentry.init({
      dsn: 'https://346f2ce5779645d69ebe94872ec167c0@sentry.io/235344',
      environment: __AZURE_ENV__
    })

    renderApp()
  } else {
    try {
      renderApp()
    } catch (err) {
      renderError(err)
    }
  }

  if (module.hot) {
    module.hot.accept('./containers/App', () => {
      renderApp()
    })
  }
})
