import React from 'react'
import PropTypes from 'prop-types'
import { SearchBox, connectStateResults } from 'react-instantsearch-dom'
import './MobileVehicleSearch.scss'

const MobileVehicleSearch = ({ placeholder, searchState }) => {
  return (
    <div className="MobileVehicleSearch">
      <SearchBox
        defaultRefinement={searchState.query}
        translations={{ placeholder: placeholder }}
      />
    </div>
  )
}

MobileVehicleSearch.propTypes = {
  placeholder: PropTypes.string,
  searchState: PropTypes.object // TODO: Make explicit
}

export default connectStateResults(MobileVehicleSearch)
